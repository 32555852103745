import { Link } from 'react-router-dom';
import { Badge, Tag } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { Product } from 'interfaces';
import { productStatusFilter } from 'constants/statusOptions';
import { ImagePreview, Pages, getDate } from '1_shared';
import {
  ProductTableCopyButton,
  ProductTableEditButton,
  ProductTableQuickViewButton,
} from '2_entities';
import { ProductTableCountCell } from '3_features';
import { userService } from 'services';
import { Permissions } from 'enums/permissions';

type TableItem = Partial<Product>;
type Column = ColumnGroupType<TableItem> | ColumnType<TableItem>;

export const ProductTableColumns: Column[] = [
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 50,
    align: 'center',
    fixed: 'left',
    render: (_: any, record: TableItem): JSX.Element => {
      const disabled = !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]);

      return (
        <>
          {!disabled && <ProductTableEditButton product={record} />}
          <ProductTableQuickViewButton product={record} />
          {!disabled && <ProductTableCopyButton product={record} />}
        </>
      );
    },
  },
  {
    title: 'Изображение',
    dataIndex: 'image',
    key: 'image',
    width: window.innerWidth < 992 ? 170 : 330,
    align: 'center',
    render: (image: string, record: TableItem) => (
      <ImagePreview
        image={image}
        gallery={record.gallery}
        width={window.innerWidth < 992 ? 150 : 300}
        height={window.innerWidth < 992 ? 100 : 250}
      />
    ),
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    width: 300,
    render: (name: string, record: TableItem) => {
      const url = `${Pages.PRODUCTS}/${record.id}`;
      const status = record.published ? 'success' : 'error';

      return (
        <>
          <div>
            <Link to={url}><Badge status={status} /> {name}</Link>
          </div>
          <span>{record.introtext}</span>
        </>
      );
    },
    sorter: true,
  },
  {
    title: 'Производитель',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    width: 150,
    responsive: ['lg', 'xl', 'xxl'],
  },
  {
    title: 'Код товара',
    dataIndex: 'code',
    key: 'code',
    width: 110,
    sorter: true,
  },
  {
    title: 'Оригинальный номер',
    dataIndex: 'originalNumbers',
    key: 'originalNumbers',
    width: 170,
    render: (originalNumbers: string[]) => {
      return <span>{originalNumbers?.join(', ')}</span>;
    },
    sorter: true,
  },
  {
    title: 'Цена, руб',
    dataIndex: 'price',
    key: 'price',
    width: 100,
    align: 'center',
    sorter: true,
  },
  {
    title: 'Количество',
    dataIndex: 'count',
    key: 'count',
    width: 160,
    render: (_: number, record: TableItem) => <ProductTableCountCell product={record} />,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (status: string) => {
      const props = productStatusFilter.find((item) => status === item.value);

      return (
        <Tag color={props?.color} key={props?.text}>
          {props?.text}
        </Tag>
      );
    },
    width: 100,
    sorter: true,
  },
  {
    title: 'Дата изменения',
    dataIndex: 'changedAt',
    key: 'changedAt',
    render: (changedAt: string, record: TableItem) => {
      const { changedBy } = record;
      const name = changedBy ? `(${changedBy?.name})` : '';

      return <span>{getDate(changedAt)} {name}</span>;
    },
    width: 150,
    responsive: ['lg', 'xl', 'xxl'],
    defaultSortOrder: 'descend',
    sorter: true,
  },
];

export const ProductTableParsingCarColumns: Column[] = [
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 60,
    align: 'center',
    render: (_: any, record: TableItem): JSX.Element => {
      const disabled = !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]);

      return (
        <>
          {!disabled && <ProductTableEditButton product={record} />}
          <ProductTableQuickViewButton product={record} />
          {!disabled && <ProductTableCopyButton product={record} />}
        </>
      );
    },
  },
  {
    title: 'Изображение',
    dataIndex: 'image',
    key: 'image',
    width: 170,
    align: 'center',
    render: (image: string, record: TableItem) => (
      <ImagePreview image={image} gallery={record.gallery} width={150} height={100} />
    ),
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    width: 600,
    render: (name: string, record: TableItem) => {
      const url = `${Pages.PRODUCTS}/${record.id}`;
      const status = record.published ? 'success' : 'error';

      return (
        <>
          <div>
            <Link to={url}><Badge status={status} /> {name}</Link>
          </div>
          <span>{record.introtext}</span>
        </>
      );
    },
    sorter: true,
  },
  {
    title: 'Код товара',
    dataIndex: 'code',
    key: 'code',
    width: 110,
    sorter: true,
  },
  {
    title: 'Оригинальный номер',
    dataIndex: 'originalNumbers',
    key: 'originalNumbers',
    responsive: ['lg', 'md'],
    render: (originalNumbers: string[]) => {
      return <span>{originalNumbers?.join(', ')}</span>;
    },
  },
  {
    title: 'Цена, руб',
    dataIndex: 'price',
    key: 'price',
    width: 100,
    align: 'center',
    sorter: true,
  },
  {
    title: 'Количество',
    dataIndex: 'count',
    key: 'count',
    width: 160,
    responsive: ['lg', 'md'],
    render: (_: number, record: TableItem) => <ProductTableCountCell product={record} />,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (status: string) => {
      const props = productStatusFilter.find((item) => status === item.value);

      return (
        <Tag color={props?.color} key={props?.text}>
          {props?.text}
        </Tag>
      );
    },
    width: 100,
    sorter: true,
    responsive: ['lg', 'md'],
  },
];
